/*eslint-disable*/
import React from "react";
import { Helmet } from "react-helmet";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Contact from "components/Contact";
import Logo from "assets/img/logo3.png";
import aboutimg from "assets/img/tönnersa.jpg";

const About = () => {
  return (
    <>
      <div style={{ whiteSpace: "pre-line" }}>
        <Helmet>
          <title>Om oss | TB Kapell</title>
        </Helmet>
        <IndexNavbar fixed />
        <section
          className="header relative pt-16 px-6 items-center flex h-screen max-h-860-px bg-cover bg-center"
          style={{
            backgroundImage: `url(${aboutimg})`,
          }}
        >
          <div className="container md:w-6/12 lg:ml-24 bg-white shadow-xl rounded-lg py-16 px-12 relative z-40">
            <div className="w-full flex flex-wrap text-center">
              <div className="sm:block flex flex-col">
                <p className="text-4xl text-center">
                  <span role="img" aria-label="love">
                    ❤️
                  </span>
                </p>
                <h2 className="font-semibold text-4xl text-black">
                  Vilka är vi?
                </h2>
                <p className="pt-8">
                  Vi är specialister på skräddarsydda kapell och presenningar
                  för alla behov. Grundat i 2023, har vårt företag sina rötter i
                  Eldsberga. Som ett familjedrivet företag är vi dedikerade till
                  att upprätthålla höga standarder av hantverk och kundservice.
                </p>
                <p className="pt-8">
                  Vår familjeanda genomsyrar varje projekt, och vi värderar de
                  långsiktiga relationerna vi bygger med våra kunder och
                  samhället.
                </p>
                <p className="pt-8">
                  Vi strävar efter att vara ledande inom tillverkning av kapell
                  och presenningar genom att erbjuda innovativa och hållbara
                  lösningar som överträffar våra kunders förväntningar.
                </p>
              </div>
              <div className="text-center"></div>
            </div>
          </div>
        </section>

        <section className="pt-16 overflow-hidden">
          <div className="container mx-auto pb-32">
            <div className="flex flex-wrap justify-center">
              <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                <h1 className="text-3xl mb-2 font-semibold leading-normal">
                  Om oss
                </h1>
                <p className="pt-4">
                  Som en central del av vår verksamhet, lägger vi stor vikt vid
                  personlig kontakt och skräddarsydda lösningar. Vi är
                  övertygade om att varje projekt är unikt och förtjänar
                  individuell uppmärksamhet och omsorg. Vårt engagemang för
                  personlig service innebär att vi noggrant lyssnar på dina
                  behov och önskemål, och arbetar nära dig genom varje steg av
                  processen.
                </p>
                <p className="pt-8">
                  Vi anpassar våra tjänster för att perfekt matcha dina
                  specifika krav och förväntningar. Vi tror att nyckeln till
                  framgång ligger i att förstå våra kunders unika situationer
                  och utmaningar.
                </p>
                <p className="pt-8">
                  Därför tar vi oss tid att verkligen lära känna dig och ditt
                  projekt. Genom att bygga starka och förtroendefulla relationer
                  kan vi erbjuda lösningar som inte bara uppfyller, utan
                  överträffar dina förväntningar.
                </p>
                <p className="pt-8">
                  Vårt mål är att skapa en upplevelse som är både personlig och
                  professionell. Vi strävar efter att vara mer än bara en
                  leverantör; vi vill vara en pålitlig partner som du kan lita
                  på för att leverera högkvalitativa resultat. Oavsett om det
                  handlar om små detaljer eller stora beslut, är vi här för att
                  stödja dig och säkerställa att ditt projekt blir en framgång.
                </p>
              </div>
              <div className="md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                <p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                            <i className="fas fa-ranking-star"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Kvalitet: Vi använder endast de bästa materialen och
                            teknikerna.
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                            <i className="fas fa-lightbulb"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Innovation: Vi är ständigt på jakt efter nya och
                            bättre sätt att tillverka våra produkter.
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                            <i className="fas fa-user-check"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Personlig service: Vi tror på att bygga långsiktiga
                            relationer med våra kunder.
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="relative bg-blueGray-200">
          <div className="container mx-auto px-4 pb-32 pt-48">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
                <div className="md:pr-12">
                  <h3 className="text-3xl font-semibold">
                    Så här går det till
                  </h3>
                  <ul className="list-none mt-6">
                    <li className="py-3">
                      <div className="flex items-center">
                        <div>
                          <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                            <i className="fas fa-1 "></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Vi börjar med att förstå dina specifika behov och
                            önskemål. Genom att lyssna på dina krav kan vi
                            rekommendera den bästa kapellösningen för din båt.
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                            <i className="fas fa-2 "></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Vi tar noggranna mått och hjälper dig att välja rätt
                            material och färg som passar dina behov. Våra
                            experter ser till att varje detalj är perfekt för
                            att säkerställa en optimal passform och lång
                            livslängd.
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                            <i className="fas fa-3 "></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Våra erfarna hantverkare syr ditt kapell med
                            precision och noggrannhet. Varje kapell genomgår
                            noggranna kvalitetskontroller för att säkerställa
                            att det uppfyller våra höga standarder.
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0 flex justify-center">
                <img
                  alt="..."
                  className="max-w-full "
                  style={{
                    transform:
                      "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                  }}
                  src={Logo}
                />
              </div>
            </div>
          </div>
        </section>
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default About;
