import React from "react";
import { Link } from "react-router-dom";
import poolImage from "assets/produkter/poolkapell-poolskydd-pool-tillverkning.jpeg";

export default function Pool() {
  return (
    <>
      <div className="w-full px-2 flex-1">
        {" "}
        <Link to="/produkter/poolskydd">
          <div className="relative flex flex-col min-w-0 break-words bg-blueGray w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500 transform hover:scale-105 ease-linear transition-all duration-150">
            <img
              alt="..."
              src={poolImage}
              className="w-full align-middle rounded-t-lg max-h-250-px object-fit-cover"
            />
            <blockquote className="relative p-8 mb-4">
              <svg
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 583 95"
                className="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                  points="-30,95 583,95 583,65"
                  className="text-lightBlue-500 fill-current"
                ></polygon>
              </svg>
              <h4 className="text-xl font-bold text-white">Poolskydd</h4>
              <p className="text-md font-light mt-2 text-white">
                Maximera din poolsäkerhet och renhet med våra anpassade
                poolskydd.
              </p>
            </blockquote>
          </div>
        </Link>
      </div>
    </>
  );
}
