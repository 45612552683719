import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

import Single from "views/Single.js";
import About from "components/About.js";
import Index from "views/Index.js";
import hayImage from "assets/produkter/hösegel-hö-kapell-tillverkning.jpeg";
import ibcImage from "assets/produkter/ibc-behållare-kapell-tillverkning.jpeg";
import truckImage from "assets/produkter/lastbilskapell-service-tillverkning-röd.jpeg";
import kitchenImage from "assets/produkter/utekökskapell-kök-kapell-tillverkning.jpeg";
import trailerImage from "assets/produkter/släpvagnskapell-tillverkning.jpeg";
import tractorImage from "assets/produkter/traktor-kapell-service-tillverkning.jpeg";
import poolImage from "assets/produkter/poolkapell-poolskydd-pool-tillverkning.jpeg";
import repairImage from "assets/tjänster/repair.png";
import specialImage from "assets/tjänster/specialkapell-tillvekning-lampkapell.jpeg";
import fireImage from "assets/produkter/laddvagn-bridgehill-batteribrand-kapell.jpeg";

const boatContent = {
  title: "Båtkapell",
  description:
    "Vi specialiserar oss på att skapa skräddarsydda båtkapell, designade för att stå emot tuffa väderförhållanden och ge maximalt skydd.",
  image:
    "https://tbkapell.se/images/produkter/b%C3%A5tkapell-kapell-b%C3%A5t-tillverkning.jpeg",
  metaTitle: "Båtkapell | Köp måttbeställda kapell för båtar",
  contentTitle: "Skräddarsydda båtkapell för alla båtmodeller ",
  contentDescription:
    "Vi erbjuder högkvalitativa, skräddarsydda båtkapell som passar perfekt till din båt, oavsett modell. \n\nVåra kapell är tillverkade av de bästa materialen på marknaden och designade för att hålla länge. \n\nGenom att ta mått direkt på din båt säkerställer vi en perfekt passform som skyddar din båt mot väder och vind. \n\nVåra båtkapell är inte bara funktionella utan också estetiskt tilltalande, vilket gör att din båt ser fantastisk ut samtidigt som den skyddas. Vi erbjuder ett brett utbud av färger och material så att du kan välja det som bäst passar din stil och dina behov.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa kapellösningen för din båt. Vi diskuterar dina preferenser och ger råd om de bästa alternativen baserat på vår erfarenhet.",
  contentHowStep2:
    "Sedan tar noggranna mått på din båt och hjälper dig att välja rätt material och färg som passar både din båt och dina preferenser. Vi erbjuder ett brett sortiment av material som är både hållbara och estetiskt tilltalande. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr ditt kapell med precision och noggrannhet. Vi använder avancerad teknik och traditionellt hantverk för att skapa ett kapell som inte bara ser bra ut utan också håller i många år. Varje kapell genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

const hayContent = {
  title: "Hösegel",
  description:
    "Hösegel är designade för att skydda hö från regn och vind, vilket säkerställer att ditt hö förblir torrt och användbart.",
  image: hayImage,
  metaTitle: "Hösegel | Köp måttbeställda hösegel",
  contentTitle: "Skräddarsydda hösegel för optimalt skydd",
  contentDescription:
    "Våra hösegel är tillverkade av högkvalitativa material som skyddar ditt hö från alla väderförhållanden. \n\nMed skräddarsydda lösningar säkerställer vi att ditt hö förblir torrt och användbart, oavsett väder. \n\nVi erbjuder ett brett utbud av material och färger för att passa dina specifika behov och preferenser.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa lösningen för dina hösegel.",
  contentHowStep2:
    "Vi tar noggranna mått och hjälper dig att välja rätt material och färg som passar dina behov. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr dina hösegel med precision och noggrannhet. Varje hösegel genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

const ibcContent = {
  title: "IBC behållare",
  description:
    "Vi är experter på skräddarsydda kapell för IBC-behållare, skapade för att säkerställa optimalt skydd och bevarande av din last i olika förhållanden.",
  image: ibcImage,
  metaTitle: "IBC behållare | Köp måttbeställda kapell för dina behållare",
  contentTitle: "Skräddarsydda kapell för IBC-behållare",
  contentDescription:
    "Våra kapell för IBC-behållare är designade för att ge maximalt skydd och hållbarhet. \n\nMed avancerade material och noggranna tillverkningsmetoder säkerställer vi att din last är skyddad under alla förhållanden. \n\nVi erbjuder skräddarsydda lösningar som passar perfekt till din IBC-behållare och uppfyller dina specifika krav.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa kapellösningen för din IBC-behållare.",
  contentHowStep2:
    "Vi tar noggranna mått och hjälper dig att välja rätt material och färg som passar dina behov. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr ditt kapell med precision och noggrannhet. Varje kapell genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

const truckContent = {
  title: "Lastbilskapell",
  description:
    "Vi är specialister på att tillverka skräddarsydda kapell för lastbilar, utformade för att säkerställa att din last transporteras säkert och skyddas mot väder och yttre påverkan.",
  image: truckImage,
  metaTitle: "Lastbilskapell | Köp måttbeställda kapell för lastbilar",
  contentTitle: "Skräddarsydda kapell för lastbilar",
  contentDescription:
    "Våra lastbilskapell är tillverkade av slitstarka material som skyddar din last under transport. \n\nMed skräddarsydda lösningar säkerställer vi att ditt kapell passar perfekt och ger maximalt skydd. \n\nVi erbjuder ett brett utbud av material och färger för att passa dina specifika behov och preferenser.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa kapellösningen för din lastbil.",
  contentHowStep2:
    "Vi tar noggranna mått och hjälper dig att välja rätt material och färg som passar dina behov. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr ditt kapell med precision och noggrannhet. Varje kapell genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

const kitchenContent = {
  title: "Utekökskapell",
  description:
    "Skydda ditt utekök med våra specialanpassade kapell. Designade för att stå emot alla väder, våra kapell är tillverkade av slitstarka material som säkerställer långvarigt skydd.",
  image: kitchenImage,
  metaTitle: "Utekökskapell | Köp måttbeställda kapell för utekök",
  contentTitle: "Skräddarsydda kapell för utekök",
  contentDescription:
    "Våra utekökskapell är designade för att skydda ditt utekök från alla väderförhållanden. \n\nMed slitstarka material och skräddarsydda lösningar säkerställer vi att ditt kapell passar perfekt och ger maximalt skydd. \n\nVi erbjuder ett brett utbud av material och färger för att passa dina specifika behov och preferenser.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa kapellösningen för ditt utekök.",
  contentHowStep2:
    "Vi tar noggranna mått och hjälper dig att välja rätt material och färg som passar dina behov. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr ditt kapell med precision och noggrannhet. Varje kapell genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

const trailerContent = {
  title: "Släpvagnskapell & flakskydd",
  description:
    "Specialiserade på släpvagnskapell och flakskydd, vi använder oss av högkvalitativ PVC och HF-svetsning för slitstarka sömmar.",
  image: trailerImage,
  metaTitle: "Släpvagnskapell | Köp måttbeställda kapell för släpvagnen",
  contentTitle: "Skräddarsydda kapell för släpvagnar",
  contentDescription:
    "Våra släpvagnskapell och flakskydd är tillverkade av högkvalitativ PVC och HF-svetsning för att säkerställa slitstarka sömmar. \n\nMed skräddarsydda lösningar säkerställer vi att ditt kapell passar perfekt och ger maximalt skydd. \n\nVi erbjuder ett brett utbud av material och färger för att passa dina specifika behov och preferenser.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa kapellösningen för din släpvagn.",
  contentHowStep2:
    "Vi tar noggranna mått och hjälper dig att välja rätt material och färg som passar dina behov. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr ditt kapell med precision och noggrannhet. Varje kapell genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

const tractorContent = {
  title: "Traktorkapell",
  description:
    "Vår specialitet ligger i att tillverka skräddarsydda kapell som skyddar din traktor mot väder och slitage.",
  image: tractorImage,
  metaTitle: "Traktorkapell | Köp måttbeställda kapell för traktorn",
  contentTitle: "Skräddarsydda kapell för traktorer",
  contentDescription:
    "Våra traktorkapell är designade för att skydda din traktor från alla väderförhållanden. \n\nMed slitstarka material och skräddarsydda lösningar säkerställer vi att ditt kapell passar perfekt och ger maximalt skydd. \n\nVi erbjuder ett brett utbud av material och färger för att passa dina specifika behov och preferenser.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa kapellösningen för din traktor.",
  contentHowStep2:
    "Vi tar noggranna mått och hjälper dig att välja rätt material och färg som passar dina behov. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr ditt kapell med precision och noggrannhet. Varje kapell genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

const poolContent = {
  title: "Poolskydd",
  description:
    "Maximera din poolsäkerhet och renhet med våra anpassade poolskydd. Framställda för att tåla de tuffaste klimatförhållanden, våra poolskydd är både robusta och estetiskt tilltalande.",
  image: poolImage,
  metaTitle: "Poolskydd | Köp måttbeställda kapell till poolen",
  contentTitle: "Skräddarsydda poolskydd",
  contentDescription:
    "Våra poolskydd är designade för att maximera säkerheten och renheten i din pool. \n\nMed robusta material och skräddarsydda lösningar säkerställer vi att ditt poolskydd passar perfekt och ger maximalt skydd. \n\nVi erbjuder ett brett utbud av material och färger för att passa dina specifika behov och preferenser.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa lösningen för ditt poolskydd.",
  contentHowStep2:
    "Vi tar noggranna mått och hjälper dig att välja rätt material och färg som passar dina behov. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr ditt poolskydd med precision och noggrannhet. Varje poolskydd genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

const repairContent = {
  title: "Reparationsarbete",
  description:
    "Vår reparationstjänst är inriktad på att förlänga livslängden på dina produkter. Med expertkunskap och precision åtgärdar vi allt från mindre slitageskador till större revor och brott.",
  image: repairImage,
  metaTitle:
    "Reparationsarbete | Reparerar alla former av kapell och presenningar",
  contentTitle: "Professionella reparationstjänster",
  contentDescription:
    "Vår reparationstjänst är utformad för att förlänga livslängden på dina produkter. \n\nMed expertkunskap och precision åtgärdar vi allt från mindre slitageskador till större revor och brott. \n\nVi arbetar noggrant för att säkerställa att varje reparation uppfyller våra höga standarder för kvalitet och hållbarhet.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa lösningen för din reparation.",
  contentHowStep2:
    "Vi inspekterar noggrant skadan och ger dig en detaljerad bedömning av vad som behöver göras. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal reparation.",
  contentHowStep3:
    "Våra erfarna hantverkare utför reparationen med precision och noggrannhet. Varje reparation genomgår noggranna kvalitetskontroller för att säkerställa att den uppfyller våra höga standarder.",
};

const specialContent = {
  title: "Specialkapell",
  description:
    "Vi är experter på att skapa skräddarsydda specialkapell som möter de mest unika och krävande behoven.",
  image: specialImage,
  metaTitle: "Specialkapell | Köp specialanpassade kapell efter era behov",
  contentTitle: "Skräddarsydda specialkapell",
  contentDescription:
    "Våra specialkapell är designade för att möta de mest unika och krävande behoven. \n\nMed innovativa designkoncept och högkvalitativa material levererar vi anpassade lösningar som kombinerar funktionalitet, hållbarhet och stil. \n\nVi erbjuder ett brett utbud av material och färger för att passa dina specifika behov och preferenser.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa lösningen för ditt specialkapell.",
  contentHowStep2:
    "Vi tar noggranna mått och hjälper dig att välja rätt material och färg som passar dina behov. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr ditt specialkapell med precision och noggrannhet. Varje kapell genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

const aboutContent = {
  title: "Om oss",
  description:
    "Vi är experter på att skapa skräddarsydda specialkapell som möter de mest unika och krävande behoven.",
  image: specialImage,
  metaTitle: "Specialkapell | Köp specialanpassade kapell efter era behov",
  contentTitle: "Skräddarsydda specialkapell",
  contentDescription:
    "Våra specialkapell är designade för att möta de mest unika och krävande behoven. \n\nMed innovativa designkoncept och högkvalitativa material levererar vi anpassade lösningar som kombinerar funktionalitet, hållbarhet och stil. \n\nVi erbjuder ett brett utbud av material och färger för att passa dina specifika behov och preferenser.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa lösningen för ditt specialkapell.",
  contentHowStep2:
    "Vi tar noggranna mått och hjälper dig att välja rätt material och färg som passar dina behov. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr ditt specialkapell med precision och noggrannhet. Varje kapell genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

const fireContent = {
  title: "Brandskydd",
  description:
    "Vi är experter på att erbjuda avancerade brandskyddslösningar som möter de högsta säkerhetsstandarderna.",
  image: fireImage,
  metaTitle: "Brandskydd | Köp avancerade brandskyddslösningar",
  contentTitle: "Avancerade brandskyddslösningar",
  contentDescription:
    "Vi är glada att tillkännage vårt senaste samarbete med Nordic Fire Protection, en ledande aktör inom brandsäkerhet. Tillsammans producerar vi brandsäkra produkter som är speciellt utformade för att skydda kablar och minska risken för brand. För mer information om våra produkter och tjänster, besök vår hemsida eller kontakta oss direkt.\n\nDeras produkter, såsom Bridgehill fire PRO X och laddvagnar är kända för sin höga kvalitet och effektivitet1. Genom detta samarbete strävar vi efter att erbjuda våra kunder de bästa lösningarna för brandsäkerhet och skydda deras verksamheter från potentiella brandrisker.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika säkerhetsbehov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa lösningen för ditt brandskydd.",
  contentHowStep2:
    "Vi genomför en noggrann analys och hjälper dig att välja rätt produkter och tjänster som passar dina behov. Våra experter ser till att varje detalj är perfekt för att säkerställa maximal säkerhet och effektivitet.",
  contentHowStep3:
    "Våra erfarna tekniker installerar och testar dina brandskyddslösningar med precision och noggrannhet. Varje produkt genomgår noggranna kvalitetskontroller för att säkerställa att den uppfyller våra höga standarder.",
};

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <Route
        path="/produkter/båtkapell"
        exact
        render={(props) => <Single {...props} content={boatContent} />}
      />
      <Route
        path="/produkter/hösegel"
        exact
        render={(props) => <Single {...props} content={hayContent} />}
      />
      <Route
        path="/produkter/ibc-behållare"
        exact
        render={(props) => <Single {...props} content={ibcContent} />}
      />
      <Route
        path="/produkter/lastbilskapell"
        exact
        render={(props) => <Single {...props} content={truckContent} />}
      />
      <Route
        path="/produkter/utekök"
        exact
        render={(props) => <Single {...props} content={kitchenContent} />}
      />
      <Route
        path="/produkter/släpvagnskapell"
        exact
        render={(props) => <Single {...props} content={trailerContent} />}
      />
      <Route
        path="/produkter/traktorkapell"
        exact
        render={(props) => <Single {...props} content={tractorContent} />}
      />
      <Route
        path="/produkter/poolskydd"
        exact
        render={(props) => <Single {...props} content={poolContent} />}
      />
      <Route
        path="/produkter/reparation"
        exact
        render={(props) => <Single {...props} content={repairContent} />}
      />
      <Route
        path="/produkter/specialkapell"
        exact
        render={(props) => <Single {...props} content={specialContent} />}
      />
      <Route
        path="/produkter/brandskydd"
        exact
        render={(props) => <Single {...props} content={fireContent} />}
      />
      <Route
        path="/om-oss"
        exact
        render={(props) => <About {...props} content={aboutContent} />}
      />
      <Route path="/" exact component={Index} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
